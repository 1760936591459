import React from "react";
import Logo from "../../Images/logo/Asset1@4x.png";
import { useTranslation } from "react-i18next";
import LanguageProvider from "../LanguageProvider/LanguageProvider";
import test from "./Navbar.module.scss";

export default function Navbar() {
  const { t } = useTranslation("global");

  return (
    <>
      <nav>
        <LanguageProvider />
      </nav>
      <section className={test.parent1}>
        <nav className="navbar navbar-expand-lg ">
          <div className={`container-fluid ${test.sectionOne} pt-3`}>
            <a className="navbar-brand w-25" href="#home">
              <img className={test.logoImage} src={Logo} alt="logoImg" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav m-auto ms-5 w-75 d-flex justify-content-around">
                <li className="nav-item">
                  <a
                    className={`${test.navItems} nav-link`}
                    aria-current="page"
                    href="#home"
                  >
                    {t("home_title1")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`${test.navItems} nav-link`} href="#about">
                    {t("home_title2")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={` nav-link ${test.navItems}`} href="#services">
                    {t("home_title3")}
                  </a>
                </li>
                <li className="nav-item">
                  <a className={`${test.navItems} nav-link`} href="#contacts">
                    {t("home_title4")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div id="home" className={`${test.home} d-flex flex-column ms-5`}>
          <h3 className={test.addy}>ADDY</h3>
          <h1 className={test.homeHead1}>{t("home_head1")}</h1>
          <h2 className={test.homeHead2}>{t("home_head2")}</h2>
          <h4 className={test.homeHead3}>{t("home_head3")}</h4>
          <h4 className={test.homeHead4}>{t("home_head4")}</h4>
        </div>
      </section>
    </>
  );
}
