import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "../../Images/logo/Asset1@4x.png";
import test4 from "./Footer.module.scss";

export default function Footer() {
  const { t } = useTranslation("global");
  return (
    <>
      <section className={test4.parent4}>
        <div className={`container ${test4.parent4Child}`}>
          <h2 className="w-100">{t("button_title")}</h2>
          <div className="text-center">
            <button type="button" className="btn btn-primary btn-lg">
              {t("button_head")}
            </button>
          </div>
          <h3 className={test4.firstPart}>{t("button_footer1")}</h3>
          <h3 className={test4.secondPart}>{t("button_footer2")}</h3>
        </div>
        <div className={test4.spacing} />
        <div className={`container-fluid ${test4.lastSection} mt-5`}>
          <div className={`row ${test4.contacts}  `}>
            <div className="col-6">
              <div id="contacts">
                <h3>{t("contactus")}</h3>
                <i className="py-2 d-block fa-solid fa-mobile-screen-button">
                  <span className="ps-2 fw-normal">+965 95587871</span>
                </i>
                <i className="fa-solid  fa-envelope">
                  <span className="ps-2 fw-normal ">{t("email")}</span>
                </i>
                <i className="d-block py-2 fa-brands fa-whatsapp">
                  <span className="ps-2">+965 96700990</span>
                </i>
                <i className={` fa-brands fa-instagram ${test4.instaIcon}`}></i>
                <a
                  className={`ps-2 ${test4.instaLink}`}
                  href="https://www.instagram.com/addykuwait?"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  addy kuwait
                </a>
              </div>
            </div>
            <div className="col-3">
              <div className={test4.footerLogo}>
                <img src={Logo} alt="logoImg" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
