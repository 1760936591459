import React, { useState } from "react";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import test from "./LanguageProvider.module.scss";

export default function LanguageProvider() {
  const languages = [
    { name: "English", code: "en", dir: "ltr" },
    { name: "العربية", code: "ar", dir: "rtl" },
  ];

  const currentLocale = Cookies.get("i18next") || "en";
  const [language, setLanguage] = useState(currentLocale);
  const { t, i18n } = useTranslation("global");

  const currentLangObj = languages.find((lang) => lang.code === currentLocale);

  const handleChangeLocale = () => {
    const newLanguage = language === "en" ? "ar" : "en";
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  useEffect(() => {
    document.body.dir = currentLangObj?.dir || "ltr";
  }, [currentLangObj]);

  return (
    <>
      <div className={test.switcher}>
        <span className={test.langTitle}>{t("languages")}</span>{" "}
        <button
          className={`btn btn-primary btn-sm ${test.langButton}`}
          onClick={handleChangeLocale}
        >
          {language === "en" ? "العربية" : "English"}
        </button>
      </div>
    </>
  );
}
