import React from "react";
import Navbar from "../Navbar/Navbar";
import Services from "../Services/Services";
import Gallery from "../Gallery/Gallery";
import Footer from "../Footer/Footer";

export default function App() {
  return (
    <>
      <Navbar />
      <Services />
      <Gallery />
      <Footer />
    </>
  );
}
