import React from "react";
import { useTranslation } from "react-i18next";
import gallery1 from "../../Images/Gallery/x_1@4x.png";
import gallery2 from "../../Images/Gallery/x23@4x.png";
import gallery3 from "../../Images/Gallery/x26@4x.png";
import test3 from "./Gallery.module.scss";

export default function Gallery() {
  const { t } = useTranslation("global");
  return (
    <>
      <section className={test3.parent3}>
        <div className="container-fluid">
          <div id="about" className={test3.gallery}>
            <h2 className={test3.galleryTitle}>{t("gallery_title")}</h2>
          </div>
          <div className={`row ${test3.gaps}`}>
            <div className="col-4 ">
              <div className={`${test3.galleryChild} ${test3.gall}`}>
                <img className="w-100  " src={gallery1} alt="Galleryimg" />
              </div>
            </div>
            <div className="col-4">
              <div className={`${test3.galleryChild} ${test3.gall2}`}>
                <img
                  className={`w-100 ${test3.midImage}`}
                  src={gallery2}
                  alt="Galleryimg"
                />
              </div>
            </div>
            <div className="col-4">
              <div className={`${test3.galleryChild} ${test3.gall3}`}>
                <img className="w-100" src={gallery3} alt="Galleryimg" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
