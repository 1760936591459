import React from "react";
import { useTranslation } from "react-i18next";
import Icon1 from "../../Images/icons/1@4x.png";
import Icon2 from "../../Images/icons/2@4x.png";
import Icon3 from "../../Images/icons/3@4x.png";
import Icon4 from "../../Images/icons/4@4x.png";
import Icon5 from "../../Images/icons/5@4x.png";
import test2 from "./Services.module.scss";

export default function Services() {
  const { t } = useTranslation("global");
  return (
    <>
      <section className={test2.parent2}>
        <div className="container">
          <div id="services" className={test2.servicesHead}>
            <h2 className={test2.serviceTitle}>{t("services_title1")}</h2>
            <h5>
              <span>{t("services_title2")}</span>
              {t("services_title3")}
            </h5>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className={test2.servicesChild}>
                <div className={test2.servicesChild1}>
                  <i className="fa-solid fa-circle pt-2" />
                  <h3>{t("icons_title1")}</h3>
                </div>
                <div className="text-center w-75">
                  <img src={Icon1} alt="Digital Marketing Icon" />
                </div>
              </div>
            </div>
            <div className={`col-md-6 ${test2.shiftedChild}`}>
              <div className={test2.servicesChild}>
                <div className={test2.servicesChild1}>
                  <i className="fa-solid fa-circle pt-2" />
                  <h3>{t("icons_title2")}</h3>
                </div>
                <div className="text-center w-75">
                  <img src={Icon2} alt="Social Media Marketing Icon" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={test2.servicesChild}>
                <div className={test2.servicesChild1}>
                  <i className="fa-solid fa-circle pt-2" />
                  <h3>{t("icons_title3")}</h3>
                </div>
                <div className="text-center w-75">
                  <img src={Icon3} alt="Photography Icon" />
                </div>
              </div>
            </div>
            <div className={`col-md-6 ${test2.shiftedChild}`}>
              <div className={test2.servicesChild}>
                <div className={test2.servicesChild1}>
                  <i className="fa-solid fa-circle pt-2" />
                  <h3>{t("icons_title4")}</h3>
                </div>
                <div className="text-center w-75">
                  <img src={Icon4} alt="Branding & Design Icon" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className={test2.servicesChild}>
                <div className={test2.servicesChild1}>
                  <i className="fa-solid fa-circle pt-2" />
                  <h3>{t("icons_title5")}</h3>
                </div>
                <div className="text-center w-75">
                  <img src={Icon5} alt="Web & App Development Icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
