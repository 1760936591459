import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App/App";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import global_en from "../src/locales/en/globals.json";
import global_ar from "../src/locales/ar/globals.json";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next.use(LanguageDetector).init({
  interpolation: { escapeValue: false },
  fallbackLng: "en",
  detection: {
    order: ["path", "localStorage", "htmlTag", "cookie"],
    caches: ["localStorage", "cookie"],
  },
  resources: {
    en: {
      global: global_en,
    },
    ar: {
      global: global_ar,
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")).render(
  <I18nextProvider i18n={i18next}>
    <App />
  </I18nextProvider>
);
